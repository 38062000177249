/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Form, useLocation } from '@remix-run/react';
import { ChevronDown, ChevronUp, CircleCheck, Plus } from 'lucide-react';
import { useRef, useState } from 'react';
import { OrganizationAvatar } from '~/components';
import {
  Organization,
  OrganizationMembership,
  OrganizationRole,
  OrganizationType,
  User,
} from '@repo/shorebird-api-client/models';
import { useLoseFocus } from '~/utils';

interface OrganizationsDropdownProps {
  user: User;
  organizations?: OrganizationMembership[];
  activeOrganization?: OrganizationMembership;
}

export const OrganizationsDropdown = (props: OrganizationsDropdownProps) => {
  const { organizations, activeOrganization } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const dropdownRef = useRef(null);
  useLoseFocus(dropdownRef, () => {
    setIsExpanded(false);
  });
  const location = useLocation();
  const isSettingsActive = location.pathname.endsWith('/settings');
  const myPersonalOrganization = organizations?.find(
    (o) =>
      o.organization.type === OrganizationType.personal &&
      o.role === OrganizationRole.owner,
  )?.organization;
  const isMyPersonalOrganization =
    activeOrganization?.organization.id === myPersonalOrganization?.id;
  const otherOrganizations = organizations?.filter(
    (o) => o.organization.id !== myPersonalOrganization?.id,
  );

  return (
    <div className="max-w-fit min-w-70 mx-auto">
      <button
        className="flex items-center justify-between w-full py-2 px-6 rounded-md shadow-sm border border-base-content/10 hover:border-accent focus:outline-none"
        onClick={() => setIsExpanded(!isExpanded)}
        aria-expanded={isExpanded}
        aria-haspopup="true"
      >
        {activeOrganization && (
          <OrganizationTile
            key={activeOrganization.organization.id}
            organization={activeOrganization.organization}
            isActive={false}
            isPersonal={isMyPersonalOrganization}
          />
        )}
        <div className="w-2"></div>
        {isExpanded ? (
          <ChevronUp className="w-5 h-5 text-gray-400" />
        ) : (
          <ChevronDown className="w-5 h-5 text-gray-400" />
        )}
      </button>

      {isExpanded && (
        <div
          ref={dropdownRef}
          className="absolute z-10 sm:max-w-md max-w-72 top-full left-20 right-0 mt-1 border border-base-content/10 hover:border-accent rounded-md z-100 transition-all duration-200 ease-in-out bg-base-200 shadow-lg shadow-base-300/40"
        >
          <div
            className="p-4 border-b border-base-content/10 hover:bg-base-content/15 cursor-pointer"
            onClick={() => {
              window.location.href = '/';
            }}
          >
            {myPersonalOrganization && (
              <OrganizationTile
                key={myPersonalOrganization.id}
                organization={myPersonalOrganization}
                isActive={isMyPersonalOrganization}
                isPersonal={true}
              />
            )}
          </div>
          <div className="p-4 border-base-content/10">
            <div className="flex items-center justify-between">
              <span className="font-light text-gray-400">Organizations</span>
              <Form reloadDocument method="post" action="/orgs/create">
                <button
                  className="text-accent hover:text-accent-focus focus:outline-none rounded-full p-1"
                  aria-label="Add organization"
                  type="submit"
                >
                  <Plus className="w-5 h-5" />
                </button>
              </Form>
            </div>
          </div>
          {otherOrganizations?.map((o) => (
            <div
              key={o.organization.id}
              className="p-4 cursor-pointer hover:bg-base-content/15"
              onClick={() => {
                window.location.href = `/orgs/${o.organization.id}/${isSettingsActive ? 'settings' : 'apps'}`;
              }}
            >
              <OrganizationTile
                key={o.organization.id}
                organization={o.organization}
                isActive={
                  o.organization.id === activeOrganization?.organization.id
                }
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

function OrganizationTile({
  organization,
  isActive,
  isPersonal,
}: {
  organization: Organization;
  isActive: boolean;
  isPersonal?: boolean;
}) {
  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center space-x-3">
        <div className="w-8 h-8 bg-base-content/10 rounded-md flex items-center justify-center">
          <OrganizationAvatar
            className="h-8 w-8"
            organization={organization}
            isPersonal={isPersonal}
          />
        </div>
        <div>
          <p className="font-medium text-left">{organization.name}</p>
        </div>
      </div>
      {isActive && <CircleCheck className="w-5 h-5 text-accent" />}
    </div>
  );
}
